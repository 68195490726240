import React from "react";
import { Link } from "gatsby";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import Heading from "components/Heading";
import PointerParent from "components/PointerParent";
import RawHTMLStyled from "components/RawHTMLStyled";
import backgroundCover from "assets/images/header_skew/generic_header.jpg";
import JumbotronCard from "components/JumbotronCard";

import ScrollToTop from "components/ScrollToTop";

export default function PrivacyPolicyPage() {
  const title = "Privacy Policy";
  const url = "privacy-policy";

  const points = [
    "headingslim1",
    "headingslim2",
    "headingslim3",
    "headingslim4",
    "headingslim5",
    "headingslim6",
    "headingslim7",
    "headingslim8",
    "headingslim9",
    "headingslim10",
  ];

  return (
    <Main page={url} pagetitle={title}>
      <HeaderSkew
        background={backgroundCover}
        title={title}
        url="faq"
        backgroundColor="small-privacy"
        appearance="grid-gray"
      >
        <JumbotronCard type="white-spaced">
          <div>
            <p>
              This policy is one way of sustaining your trust in Loanpal, LLC,
              our products and services. Our privacy policy describes how we
              collect, protect, and use information about you during your visit
              on our website.
            </p>
            <p>
              At all times, we reserve the right to disclose any information,
              including personally identifiable information, to comply with any
              applicable law, regulation, legal process or governmental request;
              to protect Loanpal rights or property (including without
              limitation in the event of a transfer of control of a Loanpal
              company, affiliate, or brand, or substantially all of its assets),
              or during an emergency when safety is at risk, or for credit fraud
              protection and risk reduction purposes.
            </p>
          </div>
        </JumbotronCard>
      </HeaderSkew>
      <PointerParent points={points}>
        <Block layer="1" name={title} guides={1} appearance="privacy-top">
          <ContentGrid appearance="privacy">
            <Heading type="h2" appearance="h3-static" id={points[9]}>
              <a href="#headingslim9">
                <span>california privacy rights notice</span>{" "}
                <small>(info below)</small>
              </a>
            </Heading>
          </ContentGrid>
        </Block>

        <Block layer="1" name="FAQ" guides={1} appearance="grid-home">
          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[0]}>
              Information Collection and Use
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  We collect information about you to help us serve your
                  financial needs, to provide you with quality products and
                  services, and to fulfill legal and regulatory requirements. We
                  consider all information about you in our possession to be
                  personal information, even if you cease to be a customer.
                  Typically, we collect this information on applications and
                  other forms you complete, through conversations you may have
                  with our loan professionals, with other representatives, and
                  in some cases, over our website. We may also collect
                  information from a wide range of other sources in order to
                  process and/or underwrite your loan application. These sources
                  may include among others, employers, attorneys, banks, title
                  insurers, insurance companies, and credit reporting agencies.
                  This information may include your name, address, phone number,
                  email address, social security number, and account numbers.
                </p>
                <p>
                  We do not sell or rent customer information. We share customer
                  information with certain employees, and with companies
                  providing services on our behalf in order to service your
                  needs. Our policy is to require all employees and companies
                  providing services on our behalf, to keep customer information
                  confidential. Our privacy policy applies to potential
                  customers, as well as current and former customers.
                </p>
                <p>
                  We may also collect, store or accumulate certain
                  non-personally identifiable information concerning your use of
                  this website, such as information regarding which of our pages
                  are most popular. Information gathered may be used in
                  aggregate form for internal business purposes, such as
                  generating statistics and developing marketing plans. We may
                  share or transfer such aggregate, non-personally identifiable
                  information with or to our affiliates.
                </p>
                <p>
                  When you fill-out a form on our website, we capture your
                  IP-address. This enables us to track and prevent fraudulent
                  attacks against our website as a security precaution and may
                  be used to prevent fraudulent applications. We do not use your
                  IP-address to contact you.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[1]}>
              Special Note to Parents
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  This website is intended for adults. We do not knowingly
                  collect personal information from children under the age of
                  13. However, if the parent or guardian of a child under 13
                  believes that the child has provided us with personally
                  identifiable information, the parent or guardian of that child
                  should contact us immediately at{" "}
                  <a
                    href="mailto:privacy@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={`Send an email to privacy@loanpal.com in a new tab`}
                  >
                    privacy@loanpal.com
                  </a>{" "}
                  if they want this information deleted from our files, so that
                  it is not in retrievable form. If we otherwise obtain
                  knowledge that we have personally identifiable information
                  about a child under 13 in retrievable form in our files, we
                  will delete the information from our existing files so that it
                  is not retrievable.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[2]}>
              Ensuring Your Security
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  We have adopted policies and procedures designed to protect
                  your personal information from unauthorized use or disclosure.
                </p>
                <p>
                  We have implemented physical, electronic, and procedural
                  safeguards to maintain confidentiality and integrity of the
                  personal information in our possession, and to guard against
                  unauthorized access. These include among other things,
                  procedures for controlling access to customer files, building
                  security programs, and information technology security
                  measures such as the use of passwords, firewall, plus virus
                  and use detection software. We continue to access new
                  technology as it becomes available, and to upgrade our
                  physical and electronic security systems as appropriate.
                </p>
                <p>
                  Our policy is to permit employees to access your personal
                  information only if they have a business purpose for using
                  such information, such as administering, providing or
                  developing our products or services. Our policy governs the
                  conduct of all of our employees and third-party vendors, to
                  safeguard personal information about consumers, and customers
                  we serve or have served in the past.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[3]}>
              Sharing Information With Companies That Provide Services For Us
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  We share personal information about you, as required or
                  permitted by law, with affiliates and third-parties such as,
                  service providers who assist us in the day-to-day operations
                  of our company in the administration, processing, servicing,
                  and sale of your loan. These affiliates and third-parties
                  include among others, title companies, appraisers, insurance
                  companies, underwriting services, processing services,
                  printing companies, software providers, marketing services,
                  and purchasers of loans. Our policy is to require affiliates
                  and third-party service providers to enter into
                  confidentiality agreements with us, prohibiting them from
                  using any personal information they obtain for any other
                  purpose, other than those for which they were retained or as
                  required by law. We may also disclose information about you,
                  when necessary or required, in legal and arbitration
                  proceedings, and to government agencies.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[4]}>
              Technology Utilized
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  There are two commonly used technologies used on this site,
                  clear gifs and cookies. We use these to monitor the
                  performance and effectiveness of the site. “Cookies” are small
                  pieces of information stored by our browser on your computer’s
                  hard drive. We do not use cookies to obtain any personally
                  identifying information. Most web browsers automatically
                  accept cookies, but you can usually change your browser
                  settings to prevent this. Please be assured that accepting a
                  cookie does not give us access to your computer or personal
                  information under any circumstances. If you disable cookies,
                  you may be unable to use some features of our site. If you
                  experience any problems with our website, please click here{" "}
                  <Link to="/bugs"> “Report a Bug”</Link>, and report the issue
                  encountered.
                </p>
                <p>
                  Clear gifs are tiny graphics with a unique identifier, similar
                  in function to cookies, and are used to track the online
                  movements of Web users. Clear gifs are not tied to users’
                  personally identifiable information.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[5]}>
              Notification of Changes
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  We may revise this Privacy Policy from time to time. If we
                  decide to change our Privacy Policy, we will post the revised
                  policy here. As we may make changes at any time without
                  notifying you, we suggest that you periodically consult this
                  Privacy Policy.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[6]}>
              Your Consent
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  Your continued participation on this website indicates your
                  acceptance of this Privacy Policy, and of the collection, use,
                  disclosure, management, and storage of your personal
                  information as described above. We may from time-to-time,
                  transfer or merge any personal information collected offline
                  to our online databases or store offline information in an
                  electronic format. We may also combine personal information we
                  collect online with information available from other sources.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[7]}>
              Contacting Us
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  If you have any questions about this privacy policy, please
                  contact us by mail at:
                </p>
                <p>
                  Loanpal
                  <br />
                  Attn: Compliance Department
                  <br />
                  8781 Sierra College Blvd.
                  <br />
                  Roseville, CA 95661
                  <br />
                </p>
                <p>
                  Effective Date of this Policy:
                  <br />
                  This policy was last revised on 5/11/2020 and takes effect
                  immediately, and is in effect until further revised.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="slim">
            <Heading type="h2" appearance="h3-static" id={points[8]}>
              California Privacy Rights Notice
            </Heading>
            <RawHTMLStyled appearance="slim">
              <>
                <p>
                  This California Privacy Rights Notice supplements the
                  information contained in Loanpal’s Privacy Policy (
                  <Link to="/privacy">loanpal.com/privacy</Link>) and applies to
                  California residents (“consumers” or “you”). If you are a
                  California resident, then you have certain rights under the
                  California Consumer Privacy Act (“CCPA”) regarding Personal
                  Information.
                </p>

                <p className="subtitle">
                  <strong>Right to Know</strong>
                </p>

                <p>
                  You have the right to know and see what data we have collected
                  about you over the past 12 months, including:
                </p>

                <ul>
                  <li>
                    The categories of Personal Information we have collected
                    about you (see{" "}
                    <a href="#categories-of-information-we-collect">
                      Categories of Information We Collect
                    </a>{" "}
                    below)
                  </li>
                  <li>
                    The categories of sources from which the Personal
                    Information is collected (see Categories of{" "}
                    <a href="#sources-for-personal-information">
                      Sources for Personal Information
                    </a>{" "}
                    below)
                  </li>
                  <li>
                    Our business or commercial purpose for collecting your
                    Personal Information (see{" "}
                    <a href="#purposes-for-collection-and-use-of-personal-information">
                      Purposes for Collection and Use of Personal Information
                    </a>{" "}
                    below)
                  </li>
                  <li>
                    The categories of third parties with whom we have shared
                    your Personal Information (see{" "}
                    <a href="#SharingPersonalInformation">
                      Sharing of Information
                    </a>{" "}
                    below) and
                  </li>
                  <li>
                    The Personal Information we have collected specifically
                    about you (see{" "}
                    <a href="#exercising-your-rights">Exercising Your Rights</a>{" "}
                    below).
                  </li>
                </ul>

                <p className="subtitle">
                  <strong>Right to Delete</strong>
                </p>
                <p>
                  You have the right to request that we delete the Personal
                  Information we have collected from you (and direct our Service
                  Providers to do the same). There are a number of exceptions,
                  however, that include, but are not limited to, when the
                  information is necessary for us to do any of the following:
                </p>

                <ul>
                  <li>Complete your transaction;</li>
                  <li>Provide services to you;</li>
                  <li>Perform a contract between us and you;</li>
                  <li>
                    Protect your security and prosecute those responsible for
                    breaching it;
                  </li>
                  <li>Fix our systems in the case of a bug;</li>
                  <li>Comply with a legal obligation; or</li>
                  <li>
                    Make other internal and lawful uses of the information that
                    are compatible with the context in which you provided it.
                  </li>
                </ul>

                <p className="subtitle">
                  <strong>Right to Prohibit Sales of Your Information</strong>
                </p>

                <p>
                  California law requires that we provide transparency about
                  personal information we “sell,” defined as Loanpal sharing
                  personal information with third parties in exchange for
                  valuable consideration.
                </p>

                <p className="subtitle">
                  <strong>
                    In the preceding twelve (12) months, Loanpal has not sold
                    your Personal information.
                  </strong>
                </p>

                <p>
                  We will provide notice to you prior to any change to our “No
                  Sales” policy. In addition, you may pro-actively prohibit any
                  such future sales of your Personal Information by us, see
                  “Exercising Your Rights” below.{" "}
                </p>

                <p className="subtitle" id="exercising-your-rights">
                  <strong>Exercising Your Rights</strong>
                </p>

                <p>
                  To exercise your specific <strong>Right to Know</strong>, or
                  your <strong>Right to Delete</strong>, please submit a
                  verifiable consumer request to us by email to{" "}
                  <a
                    href="mailto:CustomerCare@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CustomerCare@loanpal.com
                  </a>{" "}
                  or by calling us at{" "}
                  <a
                    href="tel:1-844-loanpal"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    1-844-LOANPAL
                  </a>
                  .
                </p>

                <p>
                  To exercise your{" "}
                  <strong>
                    Right to Prohibit the Sales of your Personal Information
                  </strong>{" "}
                  (including future sales) please (a) click the link{" "}
                  <Link to="/do-not-sell-my-information">
                    “Do Not Sell My Personal Information”
                  </Link>{" "}
                  or (b) submit a verifiable consumer request to us by email to{" "}
                  <a
                    href="mailto:CustomerCare@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CustomerCare@loanpal.com
                  </a>{" "}
                  or by calling us at{" "}
                  <a
                    href="tel:1-844-loanpal"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    1-844-LOANPAL
                  </a>
                  .
                </p>

                <p>
                  Only you, or someone legally authorized to act on your behalf,
                  may exercise these rights related to your Personal
                  Information. Such a request must:
                </p>

                <ul>
                  <li>
                    Provide sufficient information that allows us to reasonably
                    verify you are the person about whom we collected Personal
                    Information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us
                    to properly understand, evaluate, and respond to it.
                  </li>
                </ul>

                <p>
                  We cannot respond to your request or provide you with the
                  specific Personal Information we have if we cannot verify your
                  identity or authority to make the request and confirm the
                  Personal Information relates to you. We will verify your
                  request(s) by having you provide your name, address and
                  contact information. We will then request additional
                  identification verification to confirm your identity and/or
                  the identity and authority of your authorized representative.
                </p>

                <p>
                  We will not discriminate against you for exercising any of
                  your CCPA rights described above. Unless permitted by the
                  CCPA, we will not deny you goods or services; charge you
                  different prices or rates for goods or services; provide you a
                  different level or quality of goods or services; or suggest
                  that you may receive a different price or rate for goods or
                  services or a different level or quality of goods or services.
                </p>

                <p
                  className="subtitle"
                  id="categories-of-information-we-collect"
                >
                  <strong>Categories of Information We Collect</strong>
                </p>
                <p>
                  Loanpal collects information that identifies, relates to,
                  describes, references, is capable of being associated with, or
                  could reasonably be linked, directly or indirectly, with a
                  particular consumer or household (“Personal Information”).
                </p>

                <p>
                  Specifically, we directly and/or through our website, mobile
                  applications, or other online systems (“Systems”) have
                  collected the following categories of Personal Information
                  from consumers within the last twelve (12) months:
                </p>

                <ul>
                  <li>
                    Personal unique identifiers (SSN, name, drivers license,
                    passport number)
                  </li>
                  <li>
                    Personal information, including contact details, financial
                    information
                  </li>
                  <li>
                    Protected classification characteristics under California or
                    federal law, such as sex or marital status
                  </li>
                  <li>Purchase or other commercial information</li>
                  <li>
                    Biometric information; Geo-location information, Sensory
                    information
                  </li>
                  <li>
                    Internet or online information, information regarding
                    interactions with our websites, applications, or
                    advertisements
                  </li>
                  <li>Employment or education-related information</li>
                  <li>
                    Inferences from drawn from personal information collected
                  </li>
                </ul>

                <p>Personal Information does not include:</p>
                <ul>
                  <li>
                    Publicly available information from government records.
                  </li>
                  <li>
                    De-identified, anonymized or aggregated consumer
                    information.
                  </li>
                  <li>
                    Information excluded from the CCPA’s scope (e.g.,
                    information covered by the Fair Credit Reporting Act (FRCA),
                    the Gramm-Leach-Bliley Act (GLBA) or California Financial
                    Information Privacy Act (FIPA), and the Driver’s Privacy
                    Protection Act of 1994).
                  </li>
                </ul>

                <p className="subtitle" id="sources-for-personal-information">
                  <strong>
                    Categories of Sources for Personal Information
                  </strong>
                </p>

                <p>
                  Loanpal obtains the Personal Information from the following
                  categories of sources:
                </p>

                <ul>
                  <li>
                    Directly from you. For example, from your communications to
                    us and through the forms you complete and information you
                    provide to request our financial products.
                  </li>
                  <li>
                    Indirectly from you. For example, when you navigate through
                    the Systems and such information is collected automatically.
                    Information collected automatically may include usage
                    details, IP addresses and information collected through
                    cookies and other tracking technologies.
                  </li>
                  <li>
                    From third-party sources. For example, consumer reporting
                    agencies to process and approve your loan application, to
                    verify identity, and to offer you financial products and
                    services of interest to you.
                  </li>
                </ul>

                <p
                  className="subtitle"
                  id="purposes-for-collection-and-use-of-personal-information"
                >
                  <strong>
                    Purposes for Collection and Use of Personal Information
                  </strong>
                </p>
                <p>
                  We may use or disclose the Personal Information we collect for
                  one or more of the following business purposes:
                </p>

                <ul>
                  <li>
                    To fulfill or meet the reason you provided the information.
                  </li>
                  <li>
                    To provide, support, personalize, and develop our Systems
                    and related services.
                  </li>
                  <li>
                    To create, maintain, customize, and secure your account
                    information and to support everyday operations including
                    risk, legal, and compliance requirements.
                  </li>
                  <li>To prevent transactional fraud.</li>
                  <li>
                    To respond to your customer support requests and/or consumer
                    complaints.
                  </li>
                  <li>
                    To personalize your experience using our Systems and related
                    services to deliver content and offerings of interest to
                    you.
                  </li>
                  <li>
                    To help maintain the safety, security, and integrity of our
                    Systems, services, databases, related technology assets, and
                    our overall business.
                  </li>
                  <li>
                    For testing, research, analysis, and product development,
                    including to develop and improve our business, our Systems
                    and related services.
                  </li>
                  <li>
                    To respond to law enforcement requests and as required by
                    applicable law, court order, or governmental regulations.
                  </li>
                  <li>
                    As described to you when collecting your personal
                    information.
                  </li>
                  <li>
                    To evaluate or conduct a transaction involving some or all
                    of Loanpal’s business or assets in which such information is
                    among the assets to be included.
                  </li>
                </ul>

                <p className="subtitle" id="SharingPersonalInformation">
                  <strong>Sharing Personal Information</strong>
                </p>
                <p>
                  We share your personal information with the following
                  categories of third parties:
                </p>

                <ul>
                  <li>
                    Parties involved in the loan application, underwriting, and
                    financing process.
                  </li>
                  <li>Parties who service your account.</li>
                  <li>Parties that perform services on Loanpal’s behalf.</li>
                  <li>Parties specifically authorized by you.</li>
                  <li>
                    Loanpal’s financing partners, investors, related agents and
                    advisors.
                  </li>
                  <li>As required by law.</li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
        </Block>
        <ScrollToTop modifier={["skewed"]} guides={1} />
      </PointerParent>
    </Main>
  );
}
